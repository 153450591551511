@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.box {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;

  visibility: hidden;
  transition: visibility 0s 0.65s;

  &.containerVisible {
    transition: visibility 0s;
    visibility: visible;
  }
}

.bgMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 0;

  animation: hide-mask 0.3s 0.3s ease-out forwards;

  .containerVisible & {
    animation: none;
  }
}

@keyframes hide-mask {
  to {
    opacity: 0;
  }
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0a0a0a;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: hide 0.3s 0.25s ease-out forwards;

  @include breakpoint("sm-max") {
    animation-name: hide-mobile;
  }

  .containerVisible & {
    animation: none;
  }
}

@keyframes hide {
  to {
    transform: translateX(100%);
  }
}

@keyframes hide-mobile {
  to {
    transform: translateY(-100%);
  }
}

.loader {
  position: relative;
  z-index: 2;
  animation: disappear 0.25s ease-out forwards;
  width: 128px;

  .containerVisible & {
    transform: scale(0.75);
    opacity: 0;
    animation: appear 0.25s 0.35s ease-in forwards;
  }
}

@keyframes appear {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes disappear {
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}