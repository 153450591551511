@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";
@import "/src/styles/animations";

.bg {
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bgBox {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;

  @media screen and (max-width: 367px){
    width: 140% !important;
  }
}

.bgMask {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
}


.bgWrapper {
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint("sm-max") {
    padding: 0 10rem;
    box-sizing: border-box;
  }
}

.naft {
  width: 37rem;
  margin-right: 10rem;

  @include breakpoint("sm-max") {
    width: 25rem;
    margin-right: 5rem;
  }
}

.heading {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 50rem;
  line-height: 1.3;
  color: #fff;
  text-align: center;

  margin: 0;

  @include breakpoint("sm-max") {
    font-size: 32rem;
    line-height: 1.2;
  }
}

.sup {
  font-size: 30rem;

  @include breakpoint("sm-max") {
    font-size: 20rem;
  }
}


.actions {
  margin-top: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint("sm-max") {
    margin-top: 18rem;
  }

  @media screen and (max-width: 300px) {
    flex-direction: column;
  }
}

.action {
  display: flex;
  border-radius: 57rem;
  height: 42rem;
  font-size: 15rem;
  font-weight: normal;
  min-width: 140rem;

  padding: 0 12rem;

  @include breakpoint("sm-max") {
    font-size: 13rem;
    padding: 0 12rem;
    height: 38rem;

    min-width: 124rem;
  }

  &:not(:first-child) {
    margin: 0 0 0 25rem;

    @include breakpoint("sm-max") {
      margin: 0 0 0 18rem;
    }

    @media screen and (max-width: 300px) {
      margin: 20rem 0 0;
    }
  }
}

.actionIcon {
  max-width: 15rem;
  max-height: 15rem;
  width: auto;
  height: auto;

  @include breakpoint("sm-max") {
    max-width: 12rem;
    max-height: 12rem;
  }
}