@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";

.list {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 0;

  @include breakpoint("sm-max") {
    justify-content: space-between;
  }
}

.item {
  width: 200rem;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 40rem;

  @include breakpoint("sm-max") {
    width: 50%;
  }
}

.image {
  display: block;
  max-width: 146rem;
  max-height: 56rem;

  @include breakpoint("sm-max") {
    max-height: 40rem;
    max-width: 100%;
  }
}
