@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";

.container {
  padding-top: 180rem;
  padding-bottom: 120rem;
  box-sizing: border-box;
}

.heading {
  font-size: 40rem;
  margin: 0;
}

.strong {
  font-size: 20rem;
  margin: 5rem 0 0;
}

.text {
  font-size: 18rem;
  color: $color-medium;
  margin-top: 20rem;
}