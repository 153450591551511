$font-primary: Circular Std, sans-serif;
$font-secondary: Proxima Nova, sans-serif;

$bg-dark: #271d25;
$bg-light: #ffffff;

$color-light: #ffffff;
$color-medium: #929195;
$color-medium-darker: #6f6f79;
$color-dark: #27272e;
$color-darker: #2c2c36;

// transition
$transition-hover: all 0.25s ease-in;
$transition-hover-color: color 0.25s ease-in;
$transition-hover-opacity: opacity 0.25s ease-in;