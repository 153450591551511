@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.box {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}

.content {
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  min-height: 50vh;
  padding-top: 70rem;

  @include breakpoint("sm-max") {
    padding-top: 60rem;
  }
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;

  @include breakpoint("md-min") {
    display: none;
  }

  &.shadowVisible {
    visibility: visible;
    opacity: 1;
  }
}

.fade {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 220rem;
  height: 100%;
  background-color: white;

  transition: 0.3s;

  transform: translateX(100%);
  padding-top: 15rem;

  @include breakpoint("md-min") {
    display: none;
  }

  &.fadeActive {
    transform: translateX(0);
  }
}

.nav {
  margin: 0 20rem;
}

.hamburgerWrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 20rem;
}

.menuWrapper {
  @include breakpoint("sm-max") {
    position: absolute;
    visibility: hidden;
    background-color: white;
    max-height: 0;
    width: 100%;
    box-sizing: border-box;
    top: calc(100% + 1rem);
    left: 0;
    padding: 0 20rem;

    transition: all 0.15s ease-in;

    .containerOpen & {
      visibility: visible;
      opacity: 1;
      max-height: 500rem;
    }
  }
}

.menu {
  list-style: none;
  min-width: 0;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;

  @include breakpoint("sm-max") {
    display: block;
  }
}

.menuItem {
  margin: 0;
  padding: 0;

  height: 50rem;

  &:not(:first-child) {
    margin-left: min(3.12vw, 40rem);
  }

  @include breakpoint("sm-max") {
    &:not(:first-child) {
      margin-left: 0;
    }

    &:not(:last-child) {
      .menuLink {
        //border-bottom: 1rem solid rgb(246, 246, 248);
      }
    }
  }
}

.menuItemBtn {
  font-size: 20rem;
  height: 45rem;
  width: 150rem;
  justify-content: flex-start !important;
}

.menuLink {
  font-family: $font-primary;
  font-size: 15rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;
  margin-left: 30rem;

  height: auto;
  background: none;
  border: none;
  transition: all 0.25s ease-in;
  cursor: pointer;

  @include breakpoint("md-max") {
    font-size: 13rem;
    line-height: 1;
  }

  @include breakpoint("md-min") {
    .menuItemActive & {
      font-weight: bold;
    }
  }

  @include breakpoint("sm-max") {
    display: inline-block;
    padding: 21.3pt 0;
    width: 100%;
    box-sizing: border-box;

    font-weight: bold;
    font-size: 15rem;
    line-height: 12.3rem;
  }

  &:link {
    text-decoration: none;

    @include breakpoint("md-min") {
      .menuItemActive & {
      }
    }
  }

  &:visited {

    @include breakpoint("md-min") {
      .menuItemActive & {
      }
    }
  }

  &:hover {
    opacity: 0.85;
  }

  &:active {
    color: $color-medium;

    @include breakpoint("sm-max") {
    }
  }
}

.menuLinkActive {
}


.button {
  @include breakpoint("md-max") {
    font-size: 12rem;
    padding: 0 10rem;
    height: 30rem;
    border-radius: 6.7rem;
  }

  .poolsPage & {
    visibility: hidden;
  }
}

.buttonIconWrapper {
  display: block;
  position: relative;
  margin-right: 6rem;
  width: 17.5rem;

  @include breakpoint("md-max") {
    width: 13.3rem;
    margin-right: 4.3rem;
  }

  img {
    position: absolute;
    bottom: -2rem;
    right: 0;
    width: 17.5rem;
    height: auto;

    @include breakpoint("md-max") {
      bottom: 0;
      width: 13.3rem;
    }
  }
}
