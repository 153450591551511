$float-vertically: float-vertically 6s ease-in-out infinite;
$float-vertically-10: float-vertically-10 4s ease-in-out infinite;
$float-horizontally: float-horizontally 6s ease-in-out infinite;
$float-square: float-square 12s ease-in-out infinite alternate;

@keyframes float-vertically {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes float-vertically-10 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes float-horizontally {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0rem);
  }
}

@keyframes float-square {
  0%{
    transform: translate(0, -5px);
  }
  20% {
    transform: translate(-5px, 0);
  }
  40% {
    transform: translate(0, 5px);
  }
  80% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, -5px);
  }
}