@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";
@import "/src/styles/animations";

@mixin heading {
  font-family: $font-primary;
  font-size: 40rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;
  white-space: pre-line;

  margin: 0;

  @include breakpoint("sm-max") {
    white-space: normal;
    font-size: 30rem;
    line-height: 29.3rem;
    text-align: center;
  }
}

@mixin text {
  font-family: $font-primary;
  font-size: 19.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 30rem;
  letter-spacing: normal;
  text-align: left;
  color: $color-medium;

  margin: 0;

  @include breakpoint("sm-max") {
    font-size: 13.3rem;
    line-height: 20rem;
    text-align: center;
  }
}

@mixin video-overlay {
  position: absolute;
  width: min(220rem, 17.18vw);
}

.heading {
  @include heading;
  white-space: pre-line;

  @include breakpoint("md-max") {
    white-space: normal;
  }

  @include breakpoint("sm-max") {
    white-space: pre-line;
  }

  @include breakpoint("xs-max") {
    white-space: normal;
  }
}

.text {
  @include text;
  white-space: pre-line;
  font-size: 18rem;
  line-height: 30rem;

  @include breakpoint("md-max") {
    white-space: normal;
  }

  @include breakpoint("sm-max") {
    margin: 0 auto;
    max-width: 375rem;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 109rem;

  @include breakpoint("sm-max") {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 52rem;
  }
}

.content {
  position: relative;
  z-index: 1;
  width: 46.4vw;
  max-width: 599rem;

  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    padding-right: 73rem;

    @include breakpoint("md-min") {
      padding-left: 71rem;
    }
  }

  &:last-child {
    padding-left: 73rem;
  }

  .text {
    margin-top: 20rem;
    max-width: 520rem;

    @include breakpoint("sm-max") {
      margin-top: 15rem;
    }
  }

  @include breakpoint("sm-max") {
    width: auto;
    max-width: none;
    display: block;
    padding: 0;
    order: 1;

    &:first-child {
      padding-right: 0rem;
    }

    &:last-child {
      padding-left: 0rem;
    }
  }
}

.image {
  width: 100%;

  @include breakpoint("sm-max") {
    order: 2;
    margin: 52rem auto 0;
    width: auto;
  }
}

.video2 {
  @include video-overlay;
  position: absolute;
  left: min(95rem, 7.42vw);
  top: min(37rem, 2.89vw);
}

.video3 {
  @include video-overlay;
  position: absolute;
  right: min(212rem, 16.56vw);
  top: min(37rem, 2.89vw);
}

.video4 {
  @include video-overlay;
  position: absolute;
  left: min(128rem, 10vw);
  top: min(38rem, 2.96vw);
}

.video5 {
  @include video-overlay;
  position: absolute;
  right: min(134rem, 10.46vw);
  top: min(38rem, 2.96vw);
}

.section1 {
  padding-top: 17.5rem;
  padding-bottom: 85.5rem;

  display: flex;
  justify-content: space-between;

  @include breakpoint("md-max") {
    padding-top: 50rem;
  }

  @include breakpoint("sm-max") {
    display: block;
    padding-top: 33rem;
    padding-bottom: 45.3rem;
  }
}

.section1Left {
  flex-shrink: 0;
  width: 470rem;
  padding-top: 39rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include breakpoint("sm-max") {
    padding-top: 0;
    width: 100%;
    align-items: center;
    margin: 0 auto;
  }
}

.section1Heading {
  @include heading;
  font-size: 48.5rem;
  line-height: 55rem;
  white-space: pre-line;

  margin: 0;

  @include breakpoint("sm-max") {
    white-space: pre-line;
    text-align: center;
    font-size: 34.3rem;
    line-height: 40rem;
  }

  @include breakpoint("xs-max") {
    white-space: normal;
  }
}

.section1Text {
  @include text;
  line-height: 33rem;
  margin: 18rem 0 0;
  white-space: pre-line;

  @include breakpoint("md-max") {
    white-space: normal;
  }

  @include breakpoint("sm-max") {
    white-space: pre-line;
    text-align: center;
    font-size: 15rem;
    line-height: 22rem;
    margin: 20rem 0 0;
  }

  @include breakpoint("xs-max") {
    white-space: normal;
  }
}

.sectionButtons {
  display: flex;
  flex-direction: row;
  :first-child {
    margin-right: 10rem;
  }

  @include breakpoint("xs-max") {
    flex-direction: column;
    text-align: center;
    :first-child {
      margin-right: 0;
    }
  }
}

.section1Button {
  width: 171rem;
  margin-top: 30rem;

  &:after {
    left: 10rem !important;
  }

  @include breakpoint("sm-max") {
    margin-top: 47.7rem;
    height: 40.7rem;
    font-size: 15rem;

    &:after {
      height: 16rem !important;
    }
  }
}

.section2Button {
  width: 171rem;
  margin-top: 30rem;

  @include breakpoint("sm-max") {
    margin-top: 47.7rem;
    height: 40.7rem;
    font-size: 15rem;

    &:after {
      height: 16rem !important;
    }
  }
}

.section1ButtonIcon {
  position: relative;
  left: -2rem;
  width: 17rem;
  height: 14rem;
  margin-right: 8rem;
}

.section1Icons {
  margin-top: 27.5rem;

  @include breakpoint("sm-max") {
    margin-top: 27.7rem;
  }
}

.section1Icon {
  width: 34rem;
  display: inline-block;

  &:not(:first-child) {
    margin-left: 26rem;
  }

  @include breakpoint("sm-max") {
    width: 23rem;

    &:not(:first-child) {
      margin-left: 16rem;
    }
  }
}

.section1MobileOnly {
  display: none;

  @include breakpoint("sm-max") {
    display: block;
    position: relative;
    width: calc(100% + 40rem);
  }
}

.section1Right {
  display: none;
  @include breakpoint("md-min") {
    display: block;
    flex-shrink: 10;
  }
}

.section1Picture {
  position: relative;
  right: -21rem;

  @include breakpoint("sm-max") {
    margin: 35rem auto 0;
    right: 0;
  }
}

.sectionIdo {
  @include breakpoint("md-min") {
    padding-left: 103rem;
    padding-right: 103rem;
  }
}

.sectionIdoHeading {
  @include heading;

  font-size: 40rem;
  text-align: center;

  @include breakpoint("sm-max") {
    font-size: 26.7rem;
  }
}

.sectionIdoBanner {
  margin-top: 36.8rem;

  @include breakpoint("sm-max") {
    margin: 36.8rem auto 0;
  }
}

.sectionPool {
  height: 410rem;
  max-height: 626rem;
  margin-top: 117rem;

  @include breakpoint("sm-max") {
    margin-top: 60rem;
    height: auto;
    max-height: none;
    display: flex;
    flex-direction: column-reverse;
  }
}


.sectionPoolLeft {
  position: relative;
  height: 100%;
  z-index: 0;

  @include breakpoint("sm-max") {
    height: auto;
  }
}

.sectionPoolImage {
  position: absolute;
  left: -138rem;
  top: 0;
  width: 560rem;

  @include breakpoint("sm-max") {
    position: relative;
    right: -20rem;
    width: 95vw;
    height: auto;
    margin: 14rem 0 0;
  }
}

.sectionPoolHeading {
  @include heading;
  white-space: nowrap;

  @include breakpoint("md-max") {
    white-space: pre-line;
  };
 }

.sectionPoolText {
  @include text;

  @include breakpoint("sm-max") {
    max-width: 318rem;
    margin-top: 16rem;
  }
}

.sectionPoolIcon {
  position: relative;
  top: 5rem;
  width: 41rem;

  @include breakpoint("sm-max") {
    width: 27rem;
  }
}

.sectionPoolImageWrapper {
  @include breakpoint("sm-max") {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    width: 100%;
  }
}

.sectionPoolContent {
  padding-right: 0rem !important;

  @include breakpoint("sm-max") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.sectionPoolButton {
  width: 122rem;
  margin-top: 25rem;
}

.sectionAmbassador {
  margin-top: 71rem;
}


.sectionAmbassadorHeading {
  @include heading;
  font-size: 40rem;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  text-align: center;

}

.sectionAmbassadorIcon {
  position: relative;
  top: 7rem;
  width: 42rem;
  margin-right: 15rem;

  @include breakpoint("sm-max") {
    top: 3rem;
    width: 27rem;
    margin-right: 11rem;
  }
}

.sectionAmbassadorBanner {
  margin: 20rem auto 0;
}

.section2 {
  height: 48.9vw;
  max-height: 626rem;
  margin-top: 117rem;

  @include breakpoint("sm-max") {
    margin-top: 60rem;
    height: auto;
    max-height: none;
    display: flex;
    flex-direction: column-reverse;
  }
}

.section2Left {
  position: relative;
  height: 100%;
  z-index: 0;

  @include breakpoint("sm-max") {
    height: auto;
  }
}

.section2Image {
  position: absolute;
  left: -138rem;
  top: 0;
  width: relativeScale(669);

  @include breakpoint("sm-max") {
    position: relative;
    left: -16vw;
    width: calc(100% + 34vw);
    max-width: none;
    margin-top: 3rem;
  }
}

.section3 {
  margin-top: 50rem;

  @include breakpoint("sm-max") {
    margin-top: 9rem;
  }
}

.section7 {
  margin-top: 109rem;

  @include breakpoint("sm-max") {
    margin-top: 60rem;
  }
}

.section7Heading {
  @include heading;

  font-size: 44rem;
  line-height: 1;
  text-align: center;
  white-space: pre-line;

  @include breakpoint("sm-max") {
    font-size: 30rem;
    white-space: normal;
  }
}

.section7List {
  margin: 65rem 0 0;
  padding: 0;
  list-style: none;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint("sm-max") {
    position: relative;
    margin: 34rem 0 0;
    width: calc(100% + 30rem);
    left: -15rem;
  }
}

.section7ListItem {
  margin: 0 0 43rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 215rem;

  @include breakpoint("sm-max") {
    width: 160rem;
    margin: 0 5rem 27rem;
  }
}

.section7Avatar {
  flex-shrink: 0;

  @include breakpoint("sm-max") {
    width: 83rem;
    height: 83rem;
  }
}

.section7Name {
  font-family: $font-primary;
  font-size: 22.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: center;
  color: $color-dark;

  margin: 15rem 0 0;

  @include breakpoint("sm-max") {
    font-size: 17.7rem;
    margin-top: 10rem;
  }
}

.section7Description {
  font-family: $font-primary;
  font-size: 18rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: $color-medium;
  white-space: nowrap;

  margin: 5rem 0 0;

  @include breakpoint("sm-max") {
    font-size: 13rem;
    line-height: 1.2;
  }
}

.section7Link {
  display: block;
  margin: 15rem 0 0;

  @include breakpoint("sm-max") {
    margin: 10rem 0 0;
  }
}

.section7Icon {
  display: block;
  width: 19.5rem;
  margin-top: 10rem;

  @include breakpoint("sm-max") {
    width: 15rem;
  }
}

.sectionBrands {
  margin-top: 104rem;
}

.sectionBrandsHeading {
  @include heading;
  text-align: center;
}

.sectionBrandsText {
  @include text;
  text-align: center;
  margin-top: 13rem;
}

.sectionBrandsList {
  margin: 42rem auto;

  @include breakpoint("sm-max") {
    margin: 30rem 0 0;
  }
}

.section8 {
  margin: 39rem 0 0;

  @include breakpoint("sm-max") {
    margin-top: 30rem;
  }
}

.section8Image {
  display: block;
  margin: 0 auto;
  width: 84rem;

  @include breakpoint("sm-max") {
    width: 56rem;
  }
}

.section8Heading, .section8SubHead {
  @include heading;
  font-size: 30rem;
  line-height: 1;
  display: block;
  text-align: center;

  @include breakpoint("sm-max") {
    font-size: 21.7rem;
    line-height: 23.3rem;
  }
}

.section8Heading {
  margin: 45rem auto 0;

  @include breakpoint("sm-max") {
    margin-top: 31rem;
  }
}

.section8SubHead {
  margin: 37rem auto 0;

  @include breakpoint("sm-max") {
    margin-top: 25rem;
  }
}

.section8Text {
  @include text;
  text-align: center;
  font-size: 18rem;
  line-height: 24rem;
  margin: 15rem auto 0;
  max-width: 506rem;

  @include breakpoint("sm-max") {
    font-size: 13.3rem;
    line-height: 18.3rem;
    margin-top: 5.7rem;
  }
}

.section8Features {
  margin: 40rem 0 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;

  @include breakpoint("sm-max") {
    position: relative;
    margin-top: 25rem;
    width: calc(100% + 40rem);
    left: -20rem;
  }
}

.section8Feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  //max-width: 198rem;

  margin: 0 20rem 20rem;

  @include breakpoint("sm-max") {
    margin: 0 0 26rem;
    padding: 0 5rem;
    width: 157rem;
  }
}

.section8FeatureImage {
  width: 47rem;

  @include breakpoint("sm-max") {
    width: 38rem;
  }
}

.section8FeatureText {
  display: block;
  text-align: center;

  font-family: $font-primary;
  font-size: 18rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24rem;
  letter-spacing: normal;
  color: $color-dark;
  margin-top: 10rem;
  white-space: pre-line;

  @include breakpoint("sm-max") {
    font-size: 14.3rem;
    line-height: 19.2rem;
  }
}

.section9 {
  margin-top: 67rem;

  @include breakpoint("sm-max") {
    margin-top: 20rem;
  }
}

.section9Heading {
  @include heading;
  text-align: center;
}

.section9Map {
  margin-top: 40rem;
  display: grid;
  grid-template-columns: 184rem 230rem 158rem;
  grid-column-gap: 140rem;
  grid-row-gap: 140rem;
  justify-content: center;
  padding: 0 207rem 0 235rem;

  @include breakpoint("md-max") {
    grid-template-columns: repeat(auto-fit, 177rem);
  }

  @include breakpoint("sm-max") {
    padding: 0;
    grid-template-columns: 118rem;
    margin-top: 30rem;
    grid-gap: 40rem;
  }
}

.section9MapHead {
  position: relative;
  height: 85rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint("sm-max") {
    height: 77rem;
  }
}

.section9MapHeading {
  font-family: $font-primary;
  font-size: 25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.66;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  margin: 0;

  @include breakpoint("sm-max") {
    font-size: 24rem;
  }
}

.section9Year {
  font-family: $font-primary;
  font-size: 14.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  @include breakpoint("sm-max") {
    font-size: 13.8rem;
  }
}

.section9Line {
  position: absolute;
  bottom: 0;
  left: -32vw;
  width: 132vw;
  height: 1rem;
  background-color: #e0e0e0;
  z-index: 0;

  @include breakpoint("sm-max") {
    display: none;
  }
}

.section9MapArrow {
  position: absolute;
  width: 13rem;
  bottom: -7rem;
  z-index: 1;

  @include breakpoint("sm-max") {
    width: 12.3rem;
  }
}

.section9MapBody {
  margin: 0;
  padding: 42rem 0 0;
  list-style: none;

  @include breakpoint("sm-max") {
    padding-top: 30rem;
  }
}

.section9MapPoint {
  display: flex;
  align-items: center;

  font-family: $font-primary;
  font-size: 17rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20rem;
  letter-spacing: normal;
  text-align: left;
  color: $color-medium;

  &:not(:first-child) {
    margin-top: 30rem;
  }

  @include breakpoint("sm-max") {
    font-size: 12.5rem;

    &:not(:first-child) {
      margin-top: 30rem;
    }
  }
}

.section9MapBullet {
  display: block;
  width: 9rem;
  margin-right: 8rem;
}

.section10 {
  margin-top: 148rem;

  @include breakpoint("sm-max") {
    margin-top: 34em;
  }
}

.section10Heading {
  @include heading;
  text-align: center;
}

.section10Text {
  @include text;
  margin: 15rem 0 0;
  text-align: center;
  font-size: 18rem;
  line-height: 1;

  @include breakpoint("sm-max") {
    font-size: 13.3rem;
    margin-top: 5.7rem;
  }
}

.section10ChartWrapper {
  margin-top: 73rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint("sm-max") {
    flex-direction: column;
    margin-top: 40rem;
  }
}

.section10Chart {
  display: block;
  width: 276rem;

  @include breakpoint("sm-max") {
    width: 210rem;
    margin: 0 auto;
  }
}

.section10Legend {
  list-style: none;
  margin: 0 0 0 min(5.39vw, 69rem);
  padding: 0;

  @include breakpoint("sm-max") {
    display: inline-block;
    margin: 45rem auto 0;
    transform: translateX(12rem);
  }
}

.section10LegendItem {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 26rem;

    @include breakpoint("sm-max") {
      margin-top: 25rem;
    }
  }
}

.section10Value {
  font-family: $font-primary;
  font-size: 18rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: left;
  color: $color-medium;

  min-width: 135rem;

  @include breakpoint("sm-max") {
    font-size: 12rem;
    line-height: 13.3rem;
    min-width: 85rem;
  }
}

.section10Color {
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  flex-shrink: 0;

  margin-left: min(37.5rem, 2.92vw);

  @include breakpoint("sm-max") {
    margin-left: 20rem;
    width: 11rem;
    height: 11rem;
  }
}

.section10Name {
  font-family: $font-primary;
  font-size: 18rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  margin-left: min(37.5rem, 2.92vw);

  @include breakpoint("sm-max") {
    font-size: 12rem;
    line-height: 13.3rem;
    margin-left: 25rem;
  }
}

.section11 {
  margin-top: 150rem;
  padding-bottom: 68.5rem;

  @include breakpoint("sm-max") {
    margin-top: 57rem;
    padding-bottom: 42rem;
  }
}

.section11Card {
  position: relative;
  border-radius: 25rem;
  box-shadow: 0rem 10rem 34rem 0 rgba(40, 40, 40, 0.15);
  display: flex;
  justify-content: flex-end;
  height: 266rem;
  overflow: hidden;

  @include breakpoint("sm-max") {
    display: block;
    height: auto;
    border-radius: 16.7rem;
  }
}

.section11Bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;

  @include breakpoint("md-max") {
    opacity: 0.4;
  }

  @include breakpoint("sm-max") {
    position: initial;
    height: auto;
    width: 100%;
    opacity: 1;
  }
}

.section11Info {
  position: relative;
  z-index: 1;
  width: min(628rem, 69.06vw);
  background-image: linear-gradient(to left, #fff, rgba(255,255,255,1) 80%, rgba(255,255,255,1));

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint("md-max") {
    width: 100%;
    background-image: linear-gradient(to left, #fff, rgba(255,255,255,0.5));
  }

  @include breakpoint("sm-max") {
    box-sizing: border-box;
    padding: 0 18rem 29rem;
    margin-top: 15rem;
  }
}

.section11Heading {
  display: flex;
  align-items: center;
  margin: 0;

  font-family: $font-secondary;
  font-size: 29rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  @include breakpoint("sm-max") {
    display: block;
    text-align: center;
    line-height: 1.3;
  }
}

.section11Logo {
  height: 32.5rem;
  margin-left: 7rem;

  @include breakpoint("sm-max") {
    height: 23rem;
  }
}

.section11Text {
  font-family: $font-secondary;
  font-size: 17.3rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  margin: 6rem 0 0;

  @include breakpoint("sm-max") {
    text-align: center;
    font-size: 13.3rem;
    line-height: 20rem;
    max-width: 237rem;
    margin: 0 auto;
  }
}


.section11List {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 25rem 0 0;
  padding: 0;

  @include breakpoint("md-max") {
    justify-content: space-between;
  }
}

.section11ListItem {
  margin: 0;
  padding: 0;

  &:not(:last-child) {
    margin-right: 38rem;

    @include breakpoint("sm-max") {
      margin-right: 33rem;
    }

    @include breakpoint("xs-max") {
      margin-right: 10rem;
    }
  }
}

.section11Icon {
  width: 24rem;

  @include breakpoint("sm-max") {
    width: 20rem;
  }
}

.tiersList {
  margin: 65rem 0 0;
  padding: 0;
  list-style: none;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint("sm-max") {
    position: relative;
    margin: 34rem 0 0;
    width: calc(100% + 30rem);
    left: -15rem;
  }
}

.tiersListItem {
  padding: 20rem;
  margin: 10rem 15rem;

  border-radius: relativeScale(25);
  box-shadow: 2.8rem 9.6rem 35rem 0 rgba(0, 0, 0, 0.08);
  background-color: $bg-light;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 220rem;

  .imgLogo {
    height: 110rem;
    object-fit: contain;
  }

  .title {
    font-family: $font-primary;
    font-size: 25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: $color-dark;
    white-space: pre-line;

    margin: 10rem 0;

    @include breakpoint("sm-max") {
      white-space: normal;
      font-size: 20rem;
      line-height: 25.3rem;
      text-align: center;
    }
  }

  .block {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 180rem;
    margin-top: 10rem;

    .blockIcon {
      width: 30rem;
      height: 30rem;
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        width: 28rem;
        object-fit: contain;
      }
    }

    .blockContent {
      padding: 10rem;
      display: flex;
      flex-direction: column;

      .contentText {
        margin: 0;
        text-align: left;
        font-size: 13rem;
        padding-bottom: 3rem;
      }

      .contentValue {
        margin: 0;
        font-size: 16rem;
        text-align: left;
      }
    }
  }

  @include breakpoint("sm-max") {
    margin-top: 40rem;
  }
}

.tiersBtn {
  font-size: 20rem;
  height: 54rem;
  border-radius: 6.7rem;
  text-align: center;
  padding: 10rem 30rem;
  margin: 40rem;
}
