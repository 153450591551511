@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.box {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @include breakpoint("md-max") {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.content {
  border-top: 1rem solid #f1f1f1;
  padding: 26rem 0 31rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint("sm-max") {
    padding: 18rem 0 24rem;
  }
}

.logo {
  width: 106rem;

  @include breakpoint("sm-max") {
    width: 97.3rem;
  }
}

.copy {
  position: relative;
  left: 9rem;

  font-family: $font-secondary;
  font-size: 15rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #2f3b3b;

  margin-top: 13rem;

  @include breakpoint("sm-max") {
    font-size: 15rem;
    margin-top: 10rem;
  }
}

.empty {
  width: 300rem;
  @include breakpoint("md-max") {
    width: 0;
  }
}

.links {
  width: 300rem;
  margin-top: 30rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 26rem 0;
  @include breakpoint("md-max") {
    margin-top: 0;
    padding-top: 0;
    justify-content: center;
  }
}

.itemLink {
  font-family: $font-primary;
  font-size: 15rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: normal;
  text-align: center;
  color: $color-dark;

  margin: 0;
  padding: 0;
  height: auto;
  background: none;
  border: none;
  transition: all 0.25s ease-in;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: min(3.12vw, 40rem);
  }

  @include breakpoint("md-max") {
    font-size: 13rem;
    line-height: 1;
  }

  @include breakpoint("md-min") {
    .menuItemActive & {
      font-weight: bold;
    }
  }

  @include breakpoint("sm-max") {
    display: inline-block;
    padding: 21.3pt 0;
    width: 100%;
    box-sizing: border-box;

    font-weight: bold;
    font-size: 15rem;
    line-height: 12.3rem;
  }

  &:link {
    text-decoration: none;

    @include breakpoint("md-min") {
      .menuItemActive & {
      }
    }
  }

  &:visited {

    @include breakpoint("md-min") {
      .menuItemActive & {
      }
    }
  }

  &:hover {
    opacity: 0.85;
  }

  &:active {
    color: $color-medium;

    @include breakpoint("sm-max") {
    }
  }
}
