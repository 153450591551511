@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";
@import "/src/styles/functions/common";

$shadow: 2.8rem 9.6rem 35rem 0 rgba(0, 0, 0, 0.16);

.container {
  position: relative;
  width: relativeScale(454);
  height: relativeScale(483.5);

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(302);
    height: relativeScaleMobile(322.3);
  }
}

.video {
  position: absolute;
  z-index: 1;
  width: relativeScale(217.5);
  left: relativeScale(129);
  top: relativeScale(7);
  box-shadow: $shadow;
  background-color: $bg-light;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(145);
    left: relativeScaleMobile(79);
    top: relativeScaleMobile(4);
  }
}

.circle {
  position: absolute;
  z-index: 0;
  width: relativeScale(336);
  top: relativeScale(77.5);
  left: relativeScale(63.5);

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(224);
    top: relativeScaleMobile(51.7);
    left: relativeScaleMobile(39.3);
  }
}

.particle1 {
  position: absolute;
  width: relativeScale(77);
  top: relativeScale(58);
  left: 0;
  z-index: 2;

  animation: $float-vertically-10;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(51.3);
    top: relativeScaleMobile(38.7);
  }
}

.particle2 {
  position: absolute;
  width: relativeScale(83);
  top: relativeScale(196.5);
  right: 0;
  z-index: 2;

  animation: $float-vertically-10;
  animation-delay: 2s;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(55.3);
    top: relativeScaleMobile(131);
  }
}