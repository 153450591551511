@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";

.container {
  border-radius: 15rem;
  box-shadow: 0 6.5rem 34rem 0 rgba(40, 40, 40, 0.1);
  background-color: #ffffff;

  width: 602rem;
  overflow: hidden;

  @include breakpoint("sm-max") {
    width: 100%;
    max-width: 602rem;
  }
}

.bg {
  position: relative;
  width: 100%;
  height: 338rem;
  cursor: pointer;
  overflow: hidden;

  @include breakpoint("sm-max") {
    width: 100%;
    height: 49vw;
  }

  &:hover {
    .image {
      transform: scale(1.03);
    }
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: no-repeat center / cover;
  transform: scale(1.001);

  transition: all 0.5s ease-in-out;
}

.content {
  padding: 26rem 48rem 31rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint("sm-max") {
    padding: 15rem 11rem 18rem;
  }
}

.short {
  display: flex;
  align-items: center;
}

.avatar {
  width: 36rem;

  @include breakpoint("sm-max") {
    width: 29rem;
  }
}

.approved {
  width: 17rem;

  @include breakpoint("sm-max") {
    width: 14rem;
  }
}

.info {
  margin-left: 13.7rem;

  @include breakpoint("sm-max") {
    margin-left: 10rem;
  }
}

.name {
  font-family: $font-primary;
  font-size: 20.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  margin: 0;

  @include breakpoint("sm-max") {
    font-size: 15rem;
  }
}

.description {
  font-family: $font-primary;
  font-size: 12rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: $color-dark;

  margin: 2rem 0 0;

  @include breakpoint("sm-max") {
    font-size: 9.7rem;
  }
}

.button {
  font-size: 12.5rem;
  background: linear-gradient(60deg, #fcb961 20%, #eb38c2 50%, #1ec1fe 80% );

  @include breakpoint("sm-max") {
    font-size: 10rem;
    height: 30rem;

    padding: 0 16rem !important;
  }

  img {
    width: 16rem;
    height: 16rem;

    @include breakpoint("sm-max") {
      width: 13rem;
      margin-right: 3rem;
    }
  }
}
