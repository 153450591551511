@import "/src/styles/functions/common";
@import "/src/styles/mixins/breakpoints";

.container {
  position: relative;
  width: 223rem;
  z-index: 1;
}

.container, .video {
  border-radius: relativeScale(20);

  @include breakpoint("sm-max") {
    border-radius: relativeScaleMobile(20);
  }
}

.video {
  position: relative;
  width: 100%;
  z-index: 1;
}

.videoFrame {
  position: absolute;
  top: -1.2%;
  left: -2.58%;
  width: 105%;
  z-index: 2;
}