@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";
@import "/src/styles/functions/common";

$shadow: 2.8rem 9.6rem 35rem 0 rgba(0, 0, 0, 0.16);

.container {
  position: relative;
  width: relativeScale(471);
  height: relativeScale(483);

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(320.3);
    height: relativeScaleMobile(322);
  }
}

.video {
  position: absolute;
  z-index: 1;
  width: relativeScale(217.8);
  left: relativeScale(95.8);
  top: relativeScale(7);
  box-shadow: $shadow;
  background-color: $bg-light;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(145);
    left: relativeScaleMobile(84.6);
    top: relativeScaleMobile(4);
  }
}

.circle {
  position: absolute;
  z-index: 0;
  width: relativeScale(336);
  top: relativeScale(77.5);
  left: relativeScale(30.5);

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(224);
    top: relativeScaleMobile(51.7);
    left: relativeScaleMobile(45.3);
  }
}

.particle1 {
  position: absolute;
  left: 0;
  top: relativeScale(50);
  width: relativeScale(45.5);
  z-index: 2;

  animation: $float-square;

  @include breakpoint("sm-max") {
    top: relativeScaleMobile(30);
    width: relativeScaleMobile(33);
  }
}

.particle2 {
  position: absolute;
  width: relativeScale(56);
  bottom: relativeScale(60);
  left: relativeScale(6.5);
  z-index: 2;

  animation: $float-square;
  animation-direction: alternate-reverse;
  animation-delay: 1s;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(37.3);
    bottom: relativeScaleMobile(44);
    left: relativeScaleMobile(8.3);
  }
}

.particle3 {
  position: absolute;
  width: relativeScale(75.5);
  top: relativeScale(69);
  right: 0;
  z-index: 2;

  animation: $float-square;
  animation-direction: alternate-reverse;
  animation-delay: 3s;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(50.3);
    top: relativeScaleMobile(46);
  }
}