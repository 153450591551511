@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";

.content {
  max-width: 1000rem;
  padding: 40rem;
  margin: auto;
  height: 100%;

  h1 {
    font-family: $font-primary;
    color: $color-dark;
    font-weight: bold;
    font-size: 25rem;
    margin-bottom: 30rem;
  }

  h3 {
    font-family: $font-primary;
    color: $color-dark;
    font-size: 20rem;
    font-weight: bold;
  }

  h4 {
    font-family: $font-primary;
    color: $color-dark;
    font-size: 18rem;
    font-weight: bold;
  }

  li, p {
    font-family: $font-primary;
    color: $color-dark;
    font-size: 16rem;
    margin-bottom: 20rem;
  }
}
