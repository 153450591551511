@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

$shadow: 2.8rem 9.6rem 35rem 0 rgba(0, 0, 0, 0.16);

.container {
  position: relative;
  width: min(651rem, 50.85vw);
  height: min(532rem, 41.56vw);

  @include breakpoint("sm-max") {
    width: min(355rem, 94.66vw);
    height: min(354rem, 94.4vw);
  }
}

.video {
  position: absolute;
  z-index: 1;
  width: min(239rem, 18.67vw);
  left: min(192.5rem, 15.03vw);
  top: min(7rem, 0.54vw);
  box-shadow: $shadow;
  background-color: $bg-light;

  @include breakpoint("sm-max") {
    width: min(159.7rem, 42.58vw);
    left: min(95.7rem, 25.52vw);
  }
}

.circle {
  position: absolute;
  z-index: 0;
  width: min(376rem, 29.375vw);
  top: min(57.5rem, 4.49vw);
  left: min(122rem, 9.53vw);

  @include breakpoint("sm-max") {
    width: min(250.7rem, 66.8vw);
    left: min(44.7rem, 11.92vw);
    top: min(38.3rem, 10.21vw)
  }
}

.particle1 {
  position: absolute;
  top: min(140rem, 10.93vw);
  left: 0;
  width: min(169rem, 13.20vw);
  z-index: 2;
  border-radius: 15rem;
  box-shadow: $shadow;

  animation: $float-vertically;

  @include breakpoint("sm-max") {
    border-radius: 10rem;
    width: min(112.7rem, 30.05vw);
    top: min(99.3rem, 26.48vw);
  }
}

.particle2 {
  position: absolute;
  width: min(112rem, 8.75vw);
  top: min(77.5rem, 6.05vw);
  right: min(64.2rem, 5.01vw);
  z-index: 2;
  border-radius: 15rem;
  box-shadow: $shadow;

  animation: $float-vertically;
  animation-delay: 3s;

  @include breakpoint("sm-max") {
    border-radius: 10rem;
    width: min(74.8rem, 19.94vw);
    right: min(13.8rem, 3.68vw);
    top: min(40.4rem, 10.77vw);
  }
}

.particle3 {
  position: absolute;
  width: min(185rem, 14.4vw);
  bottom: min(82rem, 6.40vw);
  right: 0;
  z-index: 2;
  border-radius: 15rem;
  box-shadow: $shadow;

  animation: $float-horizontally;
  animation-delay: 3s;

  @include breakpoint("sm-max") {
    border-radius: 10rem;
    width: min(124.8rem, 33.28vw);
    bottom: min(74.3rem, 19.81vw);
  }
}

.image {
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  width: 50.9vw;

  @include breakpoint("sm-max") {
    position: initial;
    width: 100%;
    max-width: none;
  }
}