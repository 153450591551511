@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";
@import "/src/styles/functions/common";

$shadow: 2.8rem 9.6rem 35rem 0 rgba(0, 0, 0, 0.16);

.container {
  position: relative;
  width: relativeScale(532.5);
  height: relativeScale(483.5);

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(337.3);
    height: relativeScaleMobile(322.3);
  }
}

.video {
  position: absolute;
  z-index: 1;
  width: relativeScale(217.5);
  left: relativeScale(139);
  top: relativeScale(7);
  box-shadow: $shadow;
  background-color: $bg-light;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(145);
    left: relativeScaleMobile(94);
    top: relativeScaleMobile(4);
  }
}

.circle {
  position: absolute;
  z-index: 0;
  width: relativeScale(336);
  top: relativeScale(71);
  left: relativeScale(67);

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(224);
    top: relativeScaleMobile(51.7);
    left: relativeScaleMobile(45.3);
  }
}

.particle1 {
  position: absolute;
  width: relativeScale(78);
  top: relativeScale(39);
  left: relativeScale(18.5);
  border-radius: relativeScale(15);
  z-index: 2;

  animation: $float-square;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(51.7);
    top: relativeScaleMobile(26.1);
    left: relativeScaleMobile(9.4);
    border-radius: relativeScaleMobile(10);
  }
}

.particle2 {
  position: absolute;
  width: relativeScale(113.9);
  bottom: relativeScale(75.9);
  border-radius: relativeScale(20);
  left: 0;
  z-index: 2;

  animation: $float-square;
  animation-direction: alternate-reverse;
  animation-delay: 1s;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(75.9);
    bottom: relativeScaleMobile(46.3);
    border-radius: relativeScaleMobile(13.3);
  }
}

.particle3 {
  position: absolute;
  width: relativeScale(155.5);
  top: relativeScale(56.5);
  border-radius: relativeScale(25);
  box-shadow: 2.8rem 9.6rem 35rem 0 rgba(0, 0, 0, 0.08);
  right: 0;
  z-index: 2;

  animation: $float-square;
  animation-direction: alternate-reverse;
  animation-delay: 3s;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(88.3);
    top: relativeScaleMobile(45.3);
    border-radius: relativeScaleMobile(16.7);
    box-shadow: 1.8rem 6.4rem 23.3rem 0 rgba(0, 0, 0, 0.08);
  }
}

.particle4 {
  position: absolute;
  width: relativeScale(77.6);
  bottom: relativeScale(46.7);
  right: relativeScale(76.7);
  border-radius: relativeScale(15);
  box-shadow: 2.8rem 9.6rem 19rem 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  animation: $float-square;

  @include breakpoint("sm-max") {
    width: relativeScaleMobile(51.7);
    bottom: relativeScaleMobile(26.1);
    right: relativeScaleMobile(23.8);
    border-radius: relativeScaleMobile(10);
    box-shadow: 1.8rem 6.4rem 12.7rem 0 rgba(0, 0, 0, 0.08);
  }
}