@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/functions/common";

.container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sm {
    object-fit: contain;
    width: 15px;
    position: absolute;
    right: 10%;
    top: 20%;
  }

  .md {
    object-fit: contain;
    width: 30px;
    position: absolute;
    right: 30%;
    top: 20%;
  }

  .lg {
    object-fit: contain;
    width: 60px;
    position: absolute;
    right: 20%;
    top: 10%;
  }

  .sm2 {
    object-fit: contain;
    width: 15px;
    position: absolute;
    left: 20%;
    bottom: 20%;
  }

  .md2 {
    object-fit: contain;
    width: 30px;
    position: absolute;
    left: 40%;
    bottom: 10%;
  }

  .lg2 {
    object-fit: contain;
    width: 60px;
    position: absolute;
    left: 5%;
    bottom: 5%;
  }

  .bubble1 {
    position: absolute;
    top: -20rem;
    left: -20rem;
    height: auto;
    width: 270rem;

    @include breakpoint("sm-max") {
      width: 173rem;
    }
  }

  .bubble2 {
    position: absolute;
    bottom: -30rem;
    right: -20rem;
    width: 372rem;
    height: auto;

    @include breakpoint("sm-max") {
      width: 246rem;
    }
  }

  .logo {
    width: 100rem;
    height: 100rem;
    margin-bottom: 15px;
  }

  .heading {
    font-family: $font-primary;
    font-size: 32rem;
    line-height: 1.5;
    margin: 10rem;
    text-align: center;
  }

  .detail {
    font-family: $font-primary;
    font-size: 14rem;
    line-height: 1.5;
    margin: 0;
    text-align: center;
  }
}

@include breakpoint("sm-max") {
  .content {
    .heading {
      font-size: 25px;
    }

    .detail {
      font-size: 14rem;
    }

    .bubble1 {
      top: -15rem;
      left: -15rem;
    }
  }
}
