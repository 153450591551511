.container {
  display: inline-flex;
  align-items: flex-start;
  width: 19.3rem;
  height: 13.7rem;
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-shadow: none;
  position: relative;

  &:focus {
    outline: none;
  }
}

.containerOpen {

}

.dash {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 2rem;
  border-radius: 2rem;
  background: currentColor;
  left: 0;

  transition: all 0.15s ease-in;

  &:nth-child(1) {
    top: 0;

    .containerOpen & {
      transform: rotate(-45deg);
      top: 6rem;
    }
  }

  &:nth-child(2) {
    top: 6rem;

    .containerOpen & {
      transform: scaleX(0);
    }
  }

  &:nth-child(3) {
    bottom: 0;

    .containerOpen & {
      transform: rotate(45deg);
      bottom: 6rem;
    }
  }
}