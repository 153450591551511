@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";
@import "/src/styles/functions/common";

@mixin circle($width, $top: 0, $left: 0) {
  position: absolute;
  display: block;
  z-index: -1;
  top:  $top * 1rem;
  left: $left * 1rem;
  width: $width * 1rem;
  //animation: $float-square;
  //animation-delay: random(5)* 1s;

  @include breakpoint("sm-max") {
    left: relativeToWidthMobile($left);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  overflow: hidden;
  z-index: -1;
}

.circle1 {
  @include circle(28, 100, 34);

  @include breakpoint("sm-max") {
    @include circle(47, 679, 15);
  }
}

.circle2 {
  @include circle(11, 116, 77);

  @include breakpoint("sm-max") {
    @include circle(60, 1823, -23);
  }
}

.circle3 {
  @include circle(58, 580, 390);

  @include breakpoint("sm-max") {
    @include circle(115, 3291, -45);
  }
}

.circle4 {
  @include circle(29, 1250, 84);

  @include breakpoint("sm-max") {
    @include circle(63, 3500, 299);
  }
}

.circle5 {
  @include circle(63, 1256, 153);

  @include breakpoint("sm-max") {
    @include circle(92, 4008, -24);
  }
}

.circle6 {
  @include circle(122, 2100, 1000);

  @include breakpoint("sm-max") {
    @include circle(43, 4631, 322);
  }
}

.circle7 {
  @include circle(240, 2576, -57);

  @include breakpoint("sm-max") {
    @include circle(28, 5875, 310);
  }
}

.circle8 {
  @include circle(95, 3327, 503);

  @include breakpoint("sm-max") {
    @include circle(85, 6660, 16);
  }
}

.circle9 {
  @include circle(40, 3374, 1116);

  @include breakpoint("sm-max") {
    @include circle(35, 8424, 14);
  }
}

.circle10 {
  @include circle(74, 4290, 1126);

  @include breakpoint("sm-max") {
    @include circle(67, 8676, 301);
  }
}

.circle11 {
  @include circle(93, 4875, 1127);

  @include breakpoint("sm-max") {
    @include circle(193, 9102, 282);
  }
}

.circle12 {
  @include circle(77, 5121, 1126);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle13 {
  @include circle(266, 5297, -42);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle14 {
  @include circle(139, 5526, 1062);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle15 {
  @include circle(63, 6659, 55);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle16 {
  @include circle(266, 6645, 1078);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle17 {
  @include circle(139, 5826, 1062);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle18 {
  @include circle(139, 5826, 1062);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle19 {
  @include circle(107, 7225, 71);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle20 {
  @include circle(37, 7332, 1140);

  @include breakpoint("sm-max") {
    display: none;
  }
}

.circle21 {
  @include circle(266, 8685, 1062);

  @include breakpoint("sm-max") {
    display: none;
  }
}
