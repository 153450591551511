@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
  position: relative;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  min-height: 100px;
  height: auto;
  background: var(--bg-placeholder) no-repeat center / cover;

  @include breakpoint("sm-max") {
    border-radius: 13.3px;
  }
}


.content {

}

.image {
  display: block;
  position: relative;
  width: 100%;
  z-index: 0;
}